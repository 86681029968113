import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.dibk.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.eglo.pim.jpg'
import image5 from '../../assets/bannerlinks/berufsreise.bannerbox.jpg'
import karinbauer from '../../assets/karinbauer.jpg'
import HoverButton from '../../components/HoverButton'

export const frontmatter = {
  title: 'Diözese Innsbruck',
  slug: '/Projekte/Diözese Innsbruck'
}

const Dioezese = () => {
  const links = generateBreadcrumbsLinks('/projekte/dioezese', 'Diözese Innsbruck')
  return (
    <Layout margin={false} color="white">
    <Helmet
      title="Die Diözese Innsbruck seit 2003 im Netz"
      meta={[
        {
          name: 'description',
          content:
            'Seit 2003 vertraut die Diözese Innsbruck auf die Konzeption und Umsetzung der holzweg GmbH. Beim letzten kompletten Redesign 2016 realisierten wir ein hochkomplexes Multiportalsystem, welches aus hunderten unabhängigen Internetauftritten besteht.'
        },
        {
          name: 'keywords',
          content:
            'diözese holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Diözese Innsbruck, dibk, Kirchenbeitrag'
        },
        {
          property: 'og:description',
          content:
            'Seit 2003 vertraut die Diözese Innsbruck auf die Konzeption und Umsetzung der holzweg GmbH. Beim letzten kompletten Redesign 2016 realisierten wir ein hochkomplexes Multiportalsystem, welches aus hunderten unabhängigen Internetauftritten besteht.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/header.dibk-644a28d3193a32ffd92ba0706dac2dd0.jpg'
        }
      ]}
    />
     <BannerSmall image={BannerImage} title="Glaube verbindet" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <>
          <div>
            Die Diözese Innsbruck <br /> im Netz seit 2003
          </div>
          <HoverButton buttonStyle="" dataText="dibk.at" dataTitle="Explore!" link={"https://www.dibk.at"} />
          </>
        }
        text="Seit 2003 vertraut die Diözese Innsbruck auf die Konzeption und Umsetzung der holzweg GmbH. Beim letzten kompletten Redesign 2016 realisierten wir ein hochkomplexes Multiportalsystem, welches aus hunderten unabhängigen Internetauftritten besteht, trotzdem jedoch eine Corporate Identity aller zugehörigen Sites gewährleistet."
      />
      <QuickFacts
        facts={[
          { count: 20, suffix: ' Jahre', text: 'Kunde bei holzweg' },
          { count: 282, suffix: '', text: 'Pfarren online' },
          { count: 40, suffix: '%', text: 'Smartphone User' }
        ]}
        color="orange"
      />
      <TextBox
        title={false}
        text={
          <span>
            <h2>Multiportalsystem</h2>
            <p>Jede Pfarre bzw. Abteilung der Diözese Innsbruck kann unter einem einheitlichen Erscheinungsbild ihre eigene Website mit individuellen Belangen und Informationen ausstatten. Zu den Standardportalen gibt es Sonderportale, welche die User mit Informationen und Services rund um ihre Glaubensgemeinschaft versorgen.</p>
            <p>So hat der User beispielsweise die Möglichkeit mittels einer von holzweg umgesetzten ePaperlösung unter der Rubrik Kirchenzeitung im Kirchenmagazin <a href="https://www.tirolersonntag.at/" target="_blank">Tiroler Sonntag</a> zu schmökern oder sich z.B. über aktuelle Veranstaltungen im <a href="https://hdb.dibk.at/Programm/Alle-Veranstaltungen" target="_blank">Haus der Begegnung</a> im Veranstaltungskalender des gleichnamigen Portales zu informieren.</p>
            <p>Da natürlich auch die jungen Mitglieder nicht zu kurz kommen dürfen, gibt es für die <a href="https://jugend.dibk.at" target="_blank">Katholische Jugend</a> eine eigene Site mit genau so vielen Informations- und Serviceangeboten, aber im Besonderen auf die Interessen der jungen User abgestimmt.</p>
            <h3><i>"Innovative Ideen und kreative Lösungen"</i></h3>
            <p><i>"Wir sind langjährige Kunden der Firma Holzweg. Sie haben uns von der ersten Stunde an begleitet. Mit innovativen Ideen und  kreativen Lösungsvorschlägen, liefern uns die MitarbeiterInnen eine gute Entscheidungsbasis für die Umsetzung unserer Website.<br /><br />Die regelmässigen Workshops helfen uns verstehen, und erleichtern uns die Arbeit. Die Zusammenarbeit ist getragen von einer guten Gesprächsbasis und wir freuen uns, noch weiterhin von Ihrem Know-how profitieren zu können."</i></p>
          </span>
        }
      />
      <Image
        image={karinbauer}
        title={
          <span>
            <p><b>Dr. Karin Bauer</b><br />Leitung Pressereferat Diözese Innsbruck</p>
          </span>
        }
      />
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
      titleLeft="Berufsreise"
      imageLeft={image5}
      leftLink="/projekte/berufsreise"
      titleModsLeft={['black']}
      titleRight="EGLO PIM"
      imageRight={image4}
      rightLink="/projekte/eglo-pim"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Dioezese
